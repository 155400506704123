import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { CategorySummary } from '../components/CategorySummary';
import { ThreeDPieChart } from '../components/charts';
import { Paper, PaperTitle } from '../components/papers';
import { AppLayout } from '../layout';

/**
 *
 */
export function Categories(props) {
  const { appData, categories, charts } = props.pageContext;

  return (
    <AppLayout data={appData} title="Most In-Demand Technologies">
      <Helmet>
        <title>Technologies | StackTrends</title>
        <meta name="description" content="" />
      </Helmet>

      <Paper>
        <PaperTitle>Tech Jobs by Discipline</PaperTitle>

        <ThreeDPieChart chartId={'three_d_pie_chart_category_count'} data={charts.pieChartCategoryCount} />
      </Paper>

      {categories.map((category) => (
        <CategorySummary data={category} key={category.details.id} />
      ))}
    </AppLayout>
  );
}

Categories.propTypes = {
  pageContext: PropTypes.shape({
    appData: PropTypes.shape({}).isRequired,
    categories: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
    charts: PropTypes.shape({
      pieChartCategoryCount: PropTypes.arrayOf(
        PropTypes.shape({ name_display: PropTypes.string.isRequired, count: PropTypes.number })
      ).isRequired
    }).isRequired
  }).isRequired
};

export default Categories;

//   <div>
//     <span className="widget-technology-metrics-label">Gained the Most Ranks</span>
//     {metrics.currentRankWinners.map((item) => (
//       <div key={item.id}>
//         <span className="widget-technology-metrics-value">
//           <Link to={`/technologies/${item.category.name}/${item.name}`}>{item.name_display}</Link>
//         </span>
//         <span className="widget-technology-metrics-subtext">{`+${item.rank_movement.toLocaleString()} Ranks Gained`}</span>
//       </div>
//     ))}
//   </div>
//
//   <div>
//     <span className="widget-technology-metrics-label">Lost the Most Ranks</span>
//     {metrics.currentRankLosers.map((item) => (
//       <div key={item.id}>
//         <span className="widget-technology-metrics-value">
//           <Link to={`/technologies/${item.category.name}/${item.name}`}>{item.name_display}</Link>
//         </span>
//         <span className="widget-technology-metrics-subtext">{`${item.rank_movement.toLocaleString()} Ranks Lost`}</span>
//       </div>
//     ))}
//   </div>
