import React from 'react';

import { formatCountValue } from '../utils';

import { CountIcon, SearchIcon } from './icons';
import { Row333333 } from './grid';
import { ViewMoreLink } from './links';
import { BasicMetric } from './metrics';
import { Paper, PaperTitle } from './papers';
import { Ranking, RankingLink, RankingTable } from './rankings';
import { SectionTitle } from '../layout';

/**
 *
 */
export function CategorySummary(props) {
  const { currentRankingSegment, details, metrics } = props.data;

  return (
    <section key={details.id}>
      <SectionTitle>
        {details.name_display} Summary <ViewMoreLink to={`/technologies/${details.name}/`}>View Detail</ViewMoreLink>
      </SectionTitle>

      <Row333333>
        <BasicMetric value={`${metrics.currentTechnologyCount} Technologies`} label="Searched" icon={SearchIcon} />

        <BasicMetric
          value={`${formatCountValue(metrics.currentListingCount)} Listings`}
          label="Current"
          icon={CountIcon}
        />

        <BasicMetric
          value={`${formatCountValue(metrics.lifetimeListingCount)} Listings`}
          label="Historic"
          icon={CountIcon}
        />
      </Row333333>

      <Paper>
        <PaperTitle>Top 5 {details.name_display}</PaperTitle>
        <Ranking>
          <RankingTable data={currentRankingSegment} parentPath={`technologies/${details.name}`} />
          <RankingLink to={`/technologies/${details.name}/`}>View All Results</RankingLink>
        </Ranking>
      </Paper>
    </section>
  );
}
