import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';

import { COLOR_FONT_0, COLOR_LINK_0 } from '../colors';

/**
 *
 */
const StyledViewMoreLink = styled(Link)`
  display: none;

  @media all and (min-width: 768px) {
    display: inline-block;
    float: right;
    margin-top: 0;
    padding: 0 16px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    color: ${COLOR_FONT_0};

    &:visited {
      color: ${COLOR_FONT_0};
    }

    &:hover {
      color: ${COLOR_FONT_0};
    }
    background-color: ${COLOR_LINK_0};
    border-radius: 4px;
  }

  @media all and (min-width: 1440px) {
    font-size: 14px;
  }
`;

export function ViewMoreLink(props) {
  return (
    <StyledViewMoreLink to={props.to}>
      {props.children} <FontAwesomeIcon icon={faChevronCircleRight} />
    </StyledViewMoreLink>
  );
}
